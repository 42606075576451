import { getDataMapper, mapField, mapArray } from "@/shared";

export const mapContractCodeFromServer = getDataMapper({
    id: mapField("id", Number),
    title: mapField("title"),
    code: mapField("code"),
    options: mapField("options"),
    groupId: mapField("order_code_group_id", Number),
    groupTitle: mapField("order_code_group_title"),
    atLeastOneActive: mapField("at_least_one_active", Boolean),
});

export const mapContractCodesFromServer = mapArray(mapContractCodeFromServer);
